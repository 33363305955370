import React, { createContext, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const { activate, deactivate, account, library, chainId } = useWeb3React();

  const walletConnect = async () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          toast.error("MetaMask not connected");
          const errorMSG = error.message; //+ ' Please install Metamask';
          // alert(errorMSG);
          // activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const disconnectWallet = async () => {
    deactivate();
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    walletConnect: () => walletConnect(),
    disconnectWallet: () => disconnectWallet(),
    // getBalanceOfFun: () => getBalanceOfFun(),
    // getBasicDetailsHandler: () => getBasicDetailsHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <>
      <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>

      <ToastContainer />
    </>
  );
}
