import React, { createContext, useEffect } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "src/utils";

import { NFTRegistry, token, Finalcontractaddress } from "src/constants";
import PolkaPunkABI from "src/abis/PolkaPunkABI.json";
import axios from "axios";
import Web3 from "web3";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, active, account, library, chainId } = useWeb3React();
  const [nftList, setNftList] = React.useState([]);
  const [userTotalNFTS, setUserTotalNFTS] = React.useState(0);
  const [totalSupply, setTotalSupply] = React.useState(0);
  const [maxSupply, setMaxSupply] = React.useState(0);

  let data = {
    nftList,
    userTotalNFTS,
    maxSupply,
    totalSupply,
    getGalleryData: () => Gallery(),
    getMoreData: () => getMoreData(),
    updateUser: (account) => {
      setSession(account);
    },

    // connectWallet: () => {
    //   activate(injected, undefined, true).catch((error) => {
    //     if (error) {
    //       activate(injected);
    //     }
    //   });
    // },

    // connectWallet: async () => {
    //   try {
    //     activate(injected, undefined, true).catch((error) => {
    //       if (error) {
    //         console.log("ERROR", error);
    //         const errorMSG = error.message; //+ ' Please install Metamask';
    //         alert(errorMSG);
    //         activate(injected);
    //       }
    //     });
    //   } catch (error) {
    //     console.log("ERROR", error);
    //   }
    // },
  };
  const Gallery = async () => {
    setNftList([]);
    console.log("xyz", Finalcontractaddress);
    const contract = getContract(
      Finalcontractaddress,
      PolkaPunkABI,
      library,
      account
    );

    const txaCount = await contract.balanceOf(account);
    const txa = txaCount.toString();
    setUserTotalNFTS(txa);
    console.log("xyzp", txa);

    console.log("owner", contract.owner());
    try {
      for (let i = 0; i < txa; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        console.log("xyza", contract);
        const res = await axios.get(filter);

        if (res.status === 200) {
          // console.log('filter', filter);

          // console.log('res', res);
          setNftList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log('nftList', nftList);
  React.useEffect(() => {
    if (active === true && account && chainId === 1285) {
      Gallery();
      getMoreData();
    }
  }, [account, active, chainId]);

  React.useEffect(() => {
    getMoreData();
  }, [account]);

  const getMoreData = async () => {
    try {
      const RPC_URL = "https://rpc.moonriver.moonbeam.network";
      const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
      // console.log("httpProvider", httpProvider);
      const web3 = new Web3(httpProvider);
      const contract = new web3.eth.Contract(
        PolkaPunkABI,
        Finalcontractaddress
      );
      const totalSupp = await contract.methods.totalSupply().call();
      setTotalSupply(parseFloat(totalSupp.toString()));
      const MAX_PUNKS = await contract.methods.MAX_PUNKS_SUPPLY().call();
      setMaxSupply(parseFloat(MAX_PUNKS.toString()));
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem("userAddress");
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
