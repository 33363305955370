import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
} from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import TelegramIcon from '@material-ui/icons/Telegram'
import FacebookIcon from '@material-ui/icons/Facebook'
import { FaDiscord } from 'react-icons/fa'

import {} from 'react-feather'
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: ' #eb5aa7',
    //paddingTop: theme.spacing(5),
    //paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  borderBottmo: {
    overflow: 'hidden',
    background: '#000',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: '8px',
  },
}))

export default function Liquidity() {
  const classes = useStyles()
  return (
    <>
      {/* <Box className={classes.borderBottmo}>
        <img
          src="images/borderfooter.png"
          className="img-responsive"
          alt=" "
          style={{ width: "100%", display: "block" }}
        />
      </Box> */}
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3" className="">
                  ABOUT
                </Typography>
                <Typography variant="body2" className="">
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo commodo. Proin sodales
                  pulvinar tempor. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus.
                </Typography>
                <Typography variant="body2" className=""></Typography>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">RECENT WORKS</Typography>
                <List className="recentWork">
                  <ListItem>
                    <img
                      src="images/img1.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img2.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img3.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img4.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img5.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img6.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">TAG CLOUD</Typography>
                <Box className="tagcloud">
                  <Link>Analyze</Link>
                  <Link>audio</Link>
                  <Link>blog</Link>
                  <Link>Business</Link>
                  <Link>Creative</Link>
                  <Link>design</Link>
                  <Link>Experiment</Link>
                  <Link>Expertize</Link>
                  <Link>Business</Link>
                  <Link>Creative</Link>
                  <Link>design</Link>
                  <Link>Experiment</Link>
                  <Link>Expertize</Link>
                </Box>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">RECENT NEWS</Typography>
                <Box className="recent-posts-widget">
                  <List>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          INTEGER AT DIAM GRAVIDA FRINGILLA NIBH PRETI PURUS
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          DONEC QUIS EX VEL TINCIDUNT
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          PRAESENT IACULIS TORTOR VIVERRA
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Box className="FooterMenu">
                <List>
                  <ListItem>
                    {/*<Link href="/terms-conditions" color="inherit">*/}
                    <Typography
                      variant="h6"
                      className=""
                      style={{ color: '#ffffff' }}
                    >
                      PolkaPunks is in no way associated with Larva Labs and /
                      or CryptoPunks
                    </Typography>
                    {/*</Link>*/}
                  </ListItem>
                  {/*<ListItem>
                    <Link href="/grant-terms" color="inherit">
                      <Typography variant="body2" className="">
                        Grant Program Terms
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="/faq" color="inherit">
                      <Typography variant="body2" className="">
                        FAQ
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="/disclaimer" color="inherit">
                      <Typography variant="body2" className="">
                        Disclaimer
                      </Typography>
                    </Link>
                  </ListItem>*/}
                </List>
              </Box>
              <Box className="FooterMenu">
                <List>
                  {/*<ListItem>
                    <Link href="#" color="inherit">
                      <YouTubeIcon style={{ color: 'white' }} />
                    </Link>
                  </ListItem>*/}
                  <ListItem>
                    <Link href="https://t.co/KWKfgMiZaI?amp=1" color="inherit">
                      <FaDiscord style={{ color: 'white', fontSize: '22px' }} />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://twitter.com/polkapunks" color="inherit">
                      <TwitterIcon style={{ color: 'white' }} />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="https://t.co/KAcwMBz5Ig?amp=1" color="inherit">
                      <TelegramIcon style={{ color: 'white' }} />
                    </Link>
                  </ListItem>
                  {/*<ListItem>
                    <Link href="#" color="inherit">
                      <FacebookIcon style={{ color: 'white' }} />
                    </Link>
                  </ListItem>*/}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
