import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Box,
  Container,
  DialogTitle,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "src/context/User";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Web3 from "web3";
import { getContract } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import { token, Finalcontractaddress } from "src/constants";
import PolkaPunkABI from "src/abis/PolkaPunkABI.json";
import abi from "src/abis/Abi2.json";
import { ethers } from "ethers";
import Mintpopup from "./Mintpopup";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  //{
  //  label: 'About us',
  //  href: '/about-us',
  //},
  // {
  //   label: "NFT Breed",
  //   href: "/nft-breed",
  // },
  {
    label: "Search",
    href: "/Polkapunks",
  },
  {
    label: "Mint",
    href: "/mint",
  },
  {
    label: "Roadmap",
    href: "/roadmap",
  },

  {
    label: "My Polkapunks",
    href: "/wallet",
  },
  // {
  //   label: "",
  //   href: "/wallet",
  // },

  //{
  //  label: 'Wallet History',
  //  href: '/wallet-history',
  //},
  //{
  //  label: 'Admin',
  //  href: '/admin',
  //},
];

const useStyles = makeStyles((theme) => ({
  header: {
    background: " #eb5aa7",
    height: "94px",
    position: "relative",
    //backgroundColor: 'transparent',
    paddingRight: "59px",
    paddingLeft: "180px",
    "@media (max-width: 1280px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "75px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },

    "@media (max-width: 767px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "69px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "@media (max-width: 1493px)": {
      paddingLeft: "0px",
      //paddingRight: '0px',
      //height: '70px',
      //paddingTop: '5px',
    },
    "@media (max-width: 1499px)": {
      paddingLeft: "0px",
      //paddingRight: '0px',
      //height: '70px',
      //paddingTop: '5px',
    },
  },

  menuButton: {
    size: "18px",
    marginLeft: "38px",
    color: "#fff",
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontSize: "16px",
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "60px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#E6007A",
    width: "260px",
  },
  drawericon: {
    color: "#fff",
    position: "absolute",
    top: "3px",
    right: "20px",
    fontSize: "25px",
  },
  logoImg: {
    width: "163px",
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },

  typographydia: {
    overflow: "hidden",
    flexWrap: "nowrap",
    width: "50px",
    color: "white",
  },
  diabox: {
    height: "5px",
    backgroundColor: "white",
    borderRight: "2px solid grey",
  },
  diagrid: {},
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  typographyETH: {
    color: "white",
  },
  logoBox: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    height: "48px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",

    //width: fit-content;
    //padding: 5px;
    //border-radius: 5px;
    //background-color: #fff;
    //height: 50px;
    //justify-content: center;
    //display: flex;
    //align-items: center;
  },
  logoboxs: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    height: "50px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginLeft: "13px",
  },
}));

export default function Header() {
  // const [alertmsg,setalertMsg]=React.useState([{msg:'',snackseverity:''}]);
  const [openloader, setOpenloader] = React.useState(false);
  const handleCloseloader = () => {
    setOpenloader(false);
  };
  const handleToggle = () => {
    setOpenloader(true);
  };
  const [alertmsg, setalertMsg] = React.useState("");
  const [alertseverity, setalertSeverity] = React.useState("");
  const user = React.useContext(UserContext);
  const [isSubmit, setisSubmit] = React.useState(false);

  const classes = useStyles();
  const { account, library, chainId } = useWeb3React();
  const [numberofnft, setNumberofnft] = React.useState("");
  //  const[totalprice,setTotalprice]=React.useState();
  const [nftprice, setNftprice] = React.useState();
  // const gettotalNFTPrice=()=>{
  //  // setTotalprice(nftprice*numberofnft);
  //   return nftprice*numberofnft;
  // }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [openalert, setOpenalert] = React.useState(false);

  const handleClickalert = () => {
    setOpenalert(true);
  };

  const handleClosealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenalert(false);
    // setalertMsg({[alertmsg.msg]:"",[alertmsg.severity]:''})
    setalertMsg("");
    setalertSeverity("");
  };
  const [open2, setOpen2] = React.useState(false);

  //Mint Function
  const mintNFT = async () => {
    // if(typeof account!=='undefined')
    // {
    //   user.connectWallet();
    // }else{
    setisSubmit(true);
    if (numberofnft && numberofnft !== "") {
      handleToggle();
      // const contract = getContract(token, abi, library, account);
      const web3 = (window.web3 = new Web3(window.ethereum));
      const contract = new web3.eth.Contract(
        PolkaPunkABI,
        Finalcontractaddress
      );

      // const signer=contract.connect(account);

      try {
        console.log(contract);
        const tx = await contract.methods.mintNFT(numberofnft).send({
          value: ethers.utils
            .parseEther((numberofnft * nftprice).toString())
            .toString(),
          gasLimit: 3000000,
          from: account,
        });
        // await tx.wait();
        console.log(tx);
        if (tx.status !== false) {
          handleClickalert();
          setalertMsg("your transaction is successful!");
          setalertSeverity("success");
          handleCloseloader();
          // handleClose2();
        } else {
          handleClickalert();
          setalertMsg("your Transaction is failed pls Try again");
          setalertSeverity("error");
          console.log(alertmsg);
          handleCloseloader();
        }
      } catch (e) {
        console.log(e);
        handleClickalert();
        setalertMsg("your Transaction is failed pls Try again");
        setalertSeverity("error");
        handleClose2();
        handleCloseloader();
      }
    }
    // }
  };
  const handleClickOpen2 = async () => {
    if (typeof account === "undefined") {
      user.connectWallet();
    } else {
      //     const web3=new Web3(Web3.givenProvider);
      //   const contract=new web3.eth.Contract(abi,address);
      const contract = getContract(
        Finalcontractaddress,
        PolkaPunkABI,
        library,
        account
      );
      console.log("Contract========", contract);
      const tx = await contract.getNFTPrice();
      console.log(tx);
      // const etherValue = Web3.utils.fromWei(result ,'ether');
      //format value from bignumber to user ether ,passEther ether->big
      const etherValue = ethers.utils.formatEther(tx);
      console.log("hello");
      console.log(etherValue);
      setNftprice(etherValue);
      setOpen2(true);
      // const Owner = await contract.owner();
      //     console.log("Owner",Owner);
    }
  };
  const [ownerowner12, setownerowner12] = useState("");
  const ownerowner = async () => {
    if (typeof account === "undefined") {
      // auth.walletConnect();
    } else {
      //     const web3=new Web3(Web3.givenProvider);
      //   const contract=new web3.eth.Contract(abi,address);
      const contract = getContract(
        Finalcontractaddress,
        PolkaPunkABI,
        library,
        account
      );
      console.log("Contract========", contract);
      const ownerowner12 = await contract.owner();
      console.log(ownerowner12);
      setownerowner12(ownerowner12.toLowerCase());
      // const etherValue = Web3.utils.fromWei(result ,'ether');
      //format value from bignumber to user ether ,passEther ether->big
      // const etherValue = ethers.utils.formatEther(tx);
      console.log("hello!!!!!");
      console.log(ownerowner12);
      // setNftprice(etherValue);
      // setOpen2(true);
      // const Owner = await contract.owner();
      //     console.log("Owner",Owner);
    }
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    typographydia,
    diabox,
    typographyETH,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  const auth = useContext(AuthContext);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{getMenuButtons()}</div>
          <div
            style={{
              width: "150px",
              overflow: "hidden",
              color: "#fff",
              marginLeft: "38px",
            }}
          >
            {/* <Grid container>
          <Grid item xs={12} align="right"> */}
            {account ? (
              <>
                {/* {auth.isAdmin && (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    component={Link}
                    to="/dashboard"
                  >
                    Dashboard
                  </Button>
                )} 
                 &nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* <Button
                  // variant="contained"
                  // size="large"
                  variant="body1"
                  // color="secondary"
                  // component={Link}
                  // to='/wallet'
                  onClick={() => auth.disconnectWallet()}
                > */}
                <Typography
                  // variant="contained"
                  // size="large"
                  style={{ color: "white" }}
                  variant="body1"
                >
                  Connected
                </Typography>
                {/* {account} */}
                {/* </Button> */}
              </>
            ) : (
              <Button
                // variant="contained"
                // size="large"
                // color="secondary"
                onClick={() => auth.walletConnect()}
                // onClick={userADminHandler}
              >
                <Typography
                  // variant="contained"
                  // size="large"
                  style={{ color: "white" }}
                  variant="body1"
                >
                  Connect wallet
                </Typography>
              </Button>
            )}
            {/* </Grid>
        </Grid> */}
          </div>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {/* <img className={logoDrawer} src="images/logo.png" alt="" /> */}
            <Box className={classes.logoboxs}>
              <Link href="/">
                <Logo className={logoImg} />

                {/*<Typography
          style={{ color: 'white', fontSize: '20px', fontWeight: '700' }}
        >
          Logo
        </Typography>*/}
              </Link>
            </Box>

            {getDrawerChoices()}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <IconButton
          className={drawericon}
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon width="60px" height="60px" />
        </IconButton>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem className={menuMobile}>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <Box className={classes.logoBox}>
      <Link href="/">
        <Logo className={logoImg} />

        {/*<Typography
          style={{ color: 'white', fontSize: '20px', fontWeight: '700' }}
        >
          Logo
        </Typography>*/}
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return (
      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        {headersData.slice(0, 2).map(({ label, href }) => {
          return (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                className: menuButton,
              }}
            >
              {label}
            </Button>
          );
        })}
        {/*<Button
          className={menuButton}
          color="inherit"
          onClick={handleClickOpen2}
          component={RouterLink}
          to="/mint"
        >
          Mint
        </Button>*/}
        {/*<Button
          className={menuButton}
          color="inherit"
          component={RouterLink}
          to="/marketplace"
        >
          Marketplace
        </Button>*/}
        <Backdrop className={classes.backdrop} open={openloader}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Mintpopup open2={open2} handleClose2={handleClose2} />
        {/* <div>
          <Dialog
            fullWidth
            open={open2}
            onClose={handleClose2}
            style={{ margin: "0px" }}
          >
            <DialogTitle
              id="simple-dialog-title"
              style={{
                backgroundColor: "rgb(25 113 44 / 83%)",
                padding: "7px",
              }}
            >
              <Typography
                variant="h3"
                align="center"
                style={{ marginBottom: 16, color: "white" }}
              >
                Mint ABC
              </Typography>
              <Grid
                container
                align="left"
                direction="row"
                xs={12}
                style={{ flexWrap: "nowrap" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "112px" }}
                >
                  <Typography className={typographydia}>3000 NFTs</Typography>
                  <Box style={{ width: "110px" }} className={diabox} />
                  <Typography className={typographyETH}>0.1 ETH</Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "102px" }}
                >
                  <Typography className={typographydia} style={{}}>
                    4000 NFT's
                  </Typography>

                  <Box style={{ width: "100px" }} className={diabox} />
                  <Typography className={typographyETH}>0.3ETH</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "92px" }}
                >
                  <Typography className={typographydia}> 4000 NFTs</Typography>

                  <Box style={{ width: "90px" }} className={diabox} />
                  <Typography className={typographyETH}>0.5 ETH</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "82px" }}
                >
                  <Typography className={typographydia}> 4000 NFTs</Typography>

                  <Box style={{ width: "80px" }} className={diabox} />
                  <Typography className={typographyETH}>0.9 ETH</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "72px" }}
                >
                  <Typography className={typographydia}> 1000 NFTs</Typography>

                  <Box style={{ width: "70px" }} className={diabox} />
                  <Typography className={typographyETH}>1.7ETH</Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "62px" }}
                >
                  <Typography className={typographydia}>381 NFT's</Typography>

                  <Box style={{ width: "60px" }} className={diabox} />
                  <Typography className={typographyETH}>3.0 ETH</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "52px" }}
                >
                  <Typography className={typographydia}>3 NFTs</Typography>

                  <Box style={{ width: "55px" }} className={diabox} />
                  <Typography
                    className={typographyETH}
                    style={{ width: "55px" }}
                  >
                    100ETH
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent
              style={{
                backgroundColor: "rgb(25 113 44 / 83%)",
                border: "px solid white",
                padding: 25,
              }}
            >
              <DialogContentText style={{ backgroundColor: "black" }}>
                <Typography
                  variant="h8"
                  style={{ color: "white", fontSize: "25px" }}
                ></Typography>
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                  <Typography
                    align="left"
                    variant="h8"
                    style={{
                      marginTop: "10px",
                      color: "white",
                      fontSize: "22px",
                    }}
                    xs={4}
                  >
                    NFT Price :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Typography
                    align="left"
                    variant="h8"
                    style={{
                      marginTop: "10px",
                      color: "white",
                      fontSize: "22px",
                    }}
                  >
                    {nftprice}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                  <Typography
                    align="left"
                    variant="h8"
                    style={{
                      marginTop: "10px",
                      color: "white",
                      fontSize: "22px",
                    }}
                  >
                    Number of NFT :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <TextField
                    size="small"
                    autoFocus
                    id="name"
                    variant="outlined"
                    type="number"
                    style={{
                      minHeight: "39px",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "5px",
                    }}
                    onChange={handleChange}
                    error={isSubmit && numberofnft === ""}
                  />
                  {isSubmit && numberofnft === "" && (
                    <Typography variant="body2" style={{ color: "red" }}>
                      Enter value
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                  <Typography
                    align="left"
                    variant="h8"
                    style={{
                      marginTop: "10px",
                      color: "white",
                      fontSize: "25px",
                    }}
                    xs={4}
                  >
                    Total Price:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Typography
                    align="left"
                    variant="h8"
                    style={{
                      marginTop: "10px",
                      color: "white",
                      fontSize: "25px",
                    }}
                  >
                    {numberofnft * nftprice}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "rgb(25 113 44 / 83%)" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose2}
                style={{ color: "white", marginTop: "10px", marginBottom: 15 }}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={mintNFT}
                style={{
                  color: "white",
                  marginTop: "10px",
                  marginLeft: "25px",
                  marginRight: "13px",
                  marginBottom: 15,
                }}
              >
                {typeof account !== "undefined" ? "Mint" : "Connect"}
              </Button>

              <Snackbar
                open={openalert}
                autoHideDuration={3000}
                onClose={handleClosealert}
              >
                <Alert onClose={handleClosealert} severity={alertseverity}>
                  {alertmsg}
                </Alert>
              </Snackbar>
            </DialogActions>
          </Dialog>
        </div> */}

        {headersData.slice(2, headersData.length).map(({ label, href }) => {
          return label !== "Admin" ? (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                className: menuButton,
              }}
            >
              {label}
            </Button>
          ) : account === "0xE8C852FB61a6350caa4a5301ECaEa4F5DF2eAdE9" ? (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                className: menuButton,
              }}
            >
              {label}
            </Button>
          ) : null;
        })}
      </div>
    );
  };

  return (
    <AppBar
      className={header}
      elevation={0}
      style={
        history.location.pathname !== "/"
          ? { paddingLeft: "0px", paddingRight: "0px" }
          : null
      }
    >
      <Container
        maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
        className={containerHeight}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </Container>
      <Dialog
        open={account && chainId !== 1285}
        minWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please switch to moonriver network
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}
